/*
 * @Author: your name
 * @Date: 2022-03-15 22:30:14
 * @LastEditTime: 2022-05-07 19:54:53
 * @LastEditors: baymax-arch 1625750783@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \MyBoke\src\hooks\useLoading.js
 */
import { onMounted, inject, ref } from 'vue'

export default function (fn) {
  console.log('12345')

  //控制加载loading
  let changeDark = inject('changeDark')

  //loading状态
  let status = ref(true)

  // 开启
  let start = () => {
    changeDark(true)
  }

  start()

  // 结束 并执行回调
  let end = fn => {
    setTimeout(() => {
      changeDark(false)
      status.value = false
        fn && fn()
    }, 800)
  }

  onMounted(() => {
    let timer = setInterval(function () {
      if (document.readyState === 'complete') {
        end(fn)
        clearInterval(timer)
      }
    }, 0)
  })

  return {
    status,
    start,
    end,
  }
}
