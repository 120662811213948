/*
 * @Author: your name
 * @Date: 2022-03-13 19:35:22
 * @LastEditTime: 2022-05-07 15:26:23
 * @LastEditors: baymax-arch 1625750783@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \vue3Boke\src\pages\home\useScroll.js
 */
import { onActivated, watch } from 'vue'
import { useRoute } from 'vue-router'

export default function (loading) {
  let route = useRoute()
  //监听loading的状态
  watch(
    () => loading.status.value,
    (n, o) => {
      // load结束的话就让 route.meta.isLoad 为true
      if (!n) {
        route.meta.isLoad = true
      }
    }
  )
  // 组件激活
  onActivated(() => {
    if (!loading.status.value) {
      route.meta.isLoad = true
    }
  })
  return
}
