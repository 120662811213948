<!--
 * @Author: your name
 * @Date: 2022-04-13 13:20:39
 * @LastEditTime: 2022-05-07 19:02:30
 * @LastEditors: baymax-arch 1625750783@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \MyBoke\src\pages\search\search.vue
-->
<template>
  <div class="searchHome">
    <h3 class="title">{{ total }}个搜索结果({{ params.keyword }})：</h3>
    <ArtilceSmall
      v-seen="{ type: 'animate', class: 'animate__fadeInUp' }"
      :imgWidth="flexScreen != 'mob' ? '200px' : '100px'"
      :imgHeight="flexScreen != 'mob' ? '120px' : '60px'"
      :titleSize="flexScreen != 'mob' ? '20px' : '16px'"
      v-for="item in state.postLists"
      :key="item.id"
      :article="item"
    >
      <template v-if="flexScreen != 'mob'" #description>
        <div class="description">
          {{ item.shortContent }}
        </div>
      </template>

      <template #btn>
        <el-button
          class="ArticleSmall_btn"
          type="text"
          size="small"
          :icon="Right"
          @click="toPosts({ id: item.id })"
        >
        </el-button>
      </template>
    </ArtilceSmall>
    <div class="loadMoreHome" v-show="scrollFlag && state.postLists.length">
      <el-button
        size="small"
        type="default"
        @click="getMore()"
        :loading="moreLoading"
      >
        加载更多
      </el-button>
    </div>
    <!-- 空列表 -->
    <el-empty
      v-show="!state.postLists.length"
      class="empty"
      description=" "
      :image-size="200"
    >
      空空如也
    </el-empty>
    <div class="without" v-show="!scrollFlag">-- 已经到底了 --</div>
  </div>
</template>

<script setup>
import { Right } from "@element-plus/icons";
import {
  onActivated,
  onMounted,
  reactive,
  watch,
  ref,
  computed,
} from "vue-demi";
import useLoading from "../../hooks/useLoading";
import useScreen from "../../hooks/useScreen";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { searchPosts } from "@/api/api.js";
import ArtilceSmall from "@/components/articleList/articleSmall";
import useScroll from "@/pages/home/useScroll.js";
import { useHead } from "@vueuse/head";

let store = useStore();
let router = useRouter();
let { params, path } = useRoute();
let { flexScreen } = useScreen();
//开启loading
let loading = useLoading(() => {
  if (backTop.value) {
    window.scrollTo(0, 0);
  }
});
useScroll(loading);
let state = reactive({
  postLists: [], //文章列表
});
let backTop = ref(false); //是否返回顶部
let scrollFlag = ref(true); //控制加载数据是否滚到底部
let moreLoading = ref(false); //控制加载数据是否滚到底部
let total = ref(0); //总数量
let current = ref(1); //页码
let limit = ref(5); //偏移量

// 查询结果
let searchFn = (keyword) => {
  searchPosts({ keyword, current: current.value, limit: limit.value })
    .then((res) => {
      console.log(res);
      state.postLists = [...state.postLists, ...res.data];
      let { pageSize, total: totalRes } = res;
      total.value = totalRes;
      moreLoading.value = false;
      if (pageSize == current.value) {
        scrollFlag.value = false;
      }
    })
    .catch((err) => {});
};

// 加载更多
let getMore = () => {
  current.value++;
  moreLoading.value = true;
  searchFn(params.keyword);
};

// 去文章详情页
let toPosts = (params) => {
  router.push({
    name: "posts",
    params: { ...params },
  });
};

// 监听路由
watch(
  () => path,
  (newValue, oldValue) => {
    let newFlag = newValue.indexOf("/search") != -1;
    let oldFlag = oldValue.indexOf("/search") != -1;
    //  本页路由之间跳转
    if (newFlag && oldFlag) {
      backTop.value = true;
    }
    // 别的路由跳转
    else {
      // 从首页跳转
      if (oldValue == "/index") {
        backTop.value = true;
      }
      // 文章页跳转
      else {
        backTop.value = false;
      }
    }
  }
);

onMounted(() => {
  searchFn(params.keyword);
});

onActivated(() => {
  useHead({
    // Can be static or computed
    title: computed(
      () => "关键词：" + params.keyword + "，" + total.value + "个搜索结果"
    ),
  });
  store.commit("headInfo/UP_ACTIVE_LINK", "");
});
</script>
  
<style lang="scss" scoped>
.empty {
  background-color: var(--white_divBgc);
  height: calc(100vh - 80px) !important;
  border-radius: 10px;
  .el-empty {
    height: 100% !important;
  }
}
.loadMoreHome {
  margin-top: 5px;
  width: 100%;
  .el-button {
    padding: 8px !important;
    background-color: var(--white_divBgc) !important;
    color: var(--white_divFc) !important ;
    border: 1px solid var(--white_divBgc) !important;
  }
}

.without {
  padding: 10px 0;
  color: var(--white_divFc);
  font-size: 14px;
}
.description {
  background-color: var(--white_divBgc) !important;
  color: var(--white_divFc) !important;
  padding: 10px;
  box-sizing: border-box;
  font-size: 14px;
}
.ArticleSmall_btn {
  font-size: 18px !important;
  color: var(--white_divFc_light) !important;
}
.ArticleSmall {
  box-sizing: border-box;
  cursor: pointer;
  padding: 10px 0;
  margin-top: 10px;
}
.searchHome {
  width: 45%;
  margin: 0 auto;
  height: auto;
  min-height: calc(100vh - 80px);
  border-top: none;
  padding: 20px 40px;
  min-width: 600px;
  position: relative;
  z-index: 1;
  color: var(--white_divFc);
  .title {
    color: var(--white_divFc);
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 768px) {
  .searchHome {
    width: 90%;
    margin: 0 auto;
    height: auto;
    border-top: none;
    padding: 10px;
    min-width: 300px;
    z-index: 1;
  }
}
</style>